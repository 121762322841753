import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import "./index.css";
import { FILE_SERVER } from '../../../../constants';
import { TopNav } from '../shared';
import Curre from "../../../../assets/svg/gear.svg";
import ProfileNo from "../../WhatsApp/assets/images/profileNo.png";
function Monitoring(props) {
    const employees = useSelector(state => state.employee.employee);
    const chats = useSelector(state => state.whatsapp.chats);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedChats, setSelectedChats] = useState([]);
    const [filters, setFilters] = useState({ period: '' });
    const [dateFilter, setDateFilter] = useState(5)

    useEffect(() => {
        const fakeData = employees.map(emp => ({
            id: emp.id,
            name: emp.username,
            photo: FILE_SERVER + emp.avatar,
            messagesSent: Math.floor(Math.random() * 100),
            chatsReplied: Math.floor(Math.random() * 20),
            signInTimes: Math.floor(Math.random() * 50),
            screenShutTimes: Math.floor(Math.random() * 10),
            forwardedMessages: Math.floor(Math.random() * 5),
            repliesSent: Math.floor(Math.random() * 30),
            attachmentsSent: Math.floor(Math.random() * 15),
        }));
        setFilteredEmployees(fakeData);
    }, [employees, selectedChats, dateFilter]);

    const toggleDetails = (id) => {
        setSelectedEmployee(selectedEmployee === id ? null : id);
    };

    const toggleChatSelection = (chatId) => {
        setSelectedChats(prevSelected =>
            prevSelected.includes(chatId)
                ? prevSelected.filter(id => id !== chatId)
                : [...prevSelected, chatId]
        );
    };

    const getLang = () => {
        if (lang) {
            return (lang)
        }
        else {
            return translated_lang
        }
    }
    const [lang, setLang] = useState(null)
    const translated_lang = useSelector((state) => state.auth.lang)
    return (
        <div className={`languages-page ${props.active && "Active-page"}`}>
            <TopNav
                table_icon={Curre}
                table_name="Monitoring"
                top_name_clk={() => { }}
                setLang={(e) => setLang(e)}
                disableAdd={true}
                lang={getLang()}
                hasChild={true}
                child={() => <div className='tickets_line_top'>
                    <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
                        <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
                    </svg>
                    <div className="tickets_element_filter">
                        <div className={dateFilter === 1 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                            if (dateFilter === 1) {
                                setDateFilter(5)
                            } else {
                                setDateFilter(1)
                            }
                        }}>
                            Today
                        </div>
                        <div className={dateFilter === 2 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                            if (dateFilter === 2) {
                                setDateFilter(5)
                            } else {
                                setDateFilter(2)
                            }
                        }}>Yesterday</div>
                        <div className={dateFilter === 3 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                            if (dateFilter === 3) {
                                setDateFilter(5)
                            } else {
                                setDateFilter(3)
                            }
                        }}>Week</div>
                        <div className={dateFilter === 4 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                            if (dateFilter === 4) {
                                setDateFilter(5)
                            } else {
                                setDateFilter(4)
                            }
                        }}>Month</div>
                        <div className={dateFilter === 5 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                            if (dateFilter === 5) {
                                setDateFilter(5)
                            } else {
                                setDateFilter(5)
                            }
                        }}>All</div>
                    </div>
                </div>}
            />
            <div className='filters'>
                <div className='monitoring-chat-filters'>
                    {chats.map((chat, index) => (
                        <div key={index} className={`monitoring-chat-item ${selectedChats.includes(chat.id) ? 'selected' : ''}`} onClick={() => toggleChatSelection(chat.id)}>
                            <div className='monitoring-chat-icon'>
                                <img src={chat.whatsapp_contact.profile_photo ?? ProfileNo} alt={chat.whatsapp_contact.name} className='monitoring-chat-photo' onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = ProfileNo;
                                }} />
                            </div>
                            <div className='monitoring-status_info'>
                                <div className='monitoring-status_info_name'>{chat.whatsapp_contact?.name || chat.whatsapp_contact?.pushname || chat.whatsapp_contact?.number}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <table className='employee-table'>
                <thead>
                    <tr>
                        <th className='avatar-column'>Photo</th>
                        <th>Name</th>
                        <th>Messages Sent</th>
                        <th>Chats Replied</th>
                        <th>Sign In Times</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEmployees.map(emp => (
                        <>
                            <tr key={emp.id} className='employee-row' onClick={() => toggleDetails(emp.id)}>
                                <td className='avatar-column'><img src={emp.photo} alt={emp.name} className='employee-photo' /></td>
                                <td>{emp.name}</td>
                                <td>{emp.messagesSent}</td>
                                <td>{emp.chatsReplied}</td>
                                <td>{emp.signInTimes}</td>
                                <td className='expand-icon'>{selectedEmployee === emp.id ? '-' : '+'}</td>
                            </tr>
                            {selectedEmployee === emp.id && (
                                <tr className='details-row'>
                                    <td colSpan='6'>
                                        <div className='details-content'>
                                            <p>Screen shut downs: {emp.screenShutTimes}</p>
                                            <p>Forwarded messages: {emp.forwardedMessages}</p>
                                            <p>Replies sent: {emp.repliesSent}</p>
                                            <p>Attachments sent: {emp.attachmentsSent}</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Monitoring;