import Countries from "../Routes/Languages/svgs/Countries.svg"
import Langs from "../Routes/Languages/svgs/Langs.svg"
import Currencies from "../Routes/Languages/svgs/Currencies.svg"
import HRM from "../Routes/Languages/svgs/HRM.svg"
import gear from "../../../assets/svg/gear.svg"
import Transfer from "../../../assets/svg/Transfer.svg"

export const dashboardOptions = [
    {
        id: 2,
        name: "Work Desk",
        key: "Work Desk",
        icon: null,
        add: false,
        childreen: [
            {
                id: 12,
                name: "Transfer",
                key: "transfer",
                add: true,
                icon: Transfer,
                routes: "Transfer"
            }, {
                id: 13,
                name: "Monitoring",
                key: "monitoring",
                add: true,
                icon: gear,
                routes: "monitoring"
            }
        ],
    },

    {
        id: 3,
        name: "System",
        key: "system",
        icon: null,
        add: null,
        childreen: [
            //{id:11, name:"Languages",  routes:"full-content", add:true, icon:Langs, childreen:[]},
            { id: 11, name: "Languages", key: "languages", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 12, name: "Translate", key: "translate", routes: "full-content", add: true, icon: Langs, childreen: [] },
            { id: 2, name: "Countries", key: "countries", routes: "full-content", add: true, icon: Countries, childreen: [] },
            { id: 2, name: "Currencies", key: "currencies", routes: "full-content", add: true, icon: Currencies, childreen: [] },]
    },
    {
        id: 3,
        name: "Management",
        key: "management",
        icon: null,
        add: null,
        childreen: [{ id: 1, name: "HRM", key: "hrm", routes: "HRM", add: true, icon: HRM, childreen: [] },

        ]
    }
]
export const getDashboardOptions = ({ roles }) => {
    if (roles?.includes("USER_FULL_ACCESS")) {
        return dashboardOptions
    } else {
        let options = []
        if ((roles?.includes("ACCOUNT_MODULE") && !roles?.includes("USER_EDIT_EMPLOYEE_SALARY"))) {
            options.push({
                id: 3,
                name: "Management",
                key: "management",
                icon: null,
                add: null,
                childreen: [{ id: 1, name: "HRM", key: "hrm", routes: "HRM", add: true, icon: HRM, childreen: [] },]
            })
        }
        if (roles?.includes("ACCESS_TICKETING_PAGE") && !roles?.includes("USER_FULL_ACCESS")) {
            options.push({
                id: 2,
                name: "Work Desk",
                key: "Work Desk",
                icon: null,
                add: false,
                childreen: [
                    {
                        id: 12,
                        name: "Employee Transfer",
                        key: "Employee Transfer",
                        add: true,
                        icon: Transfer,
                        routes: "Employee Transfer"
                    }]
            })
        }
        if (roles?.includes("VIEW_BROKER_TICKETING_PAGE") && !roles?.includes("USER_FULL_ACCESS")) {
            options.push({
                id: 2,
                name: "Work Desk",
                key: "Work Desk",
                icon: null,
                add: false,
                childreen: [
                    {
                        id: 12,
                        name: "Deposit Tickets",
                        key: "Deposit Tickets",
                        add: true,
                        icon: Transfer,
                        routes: "Deposit Tickets"
                    }]
            })
        }
        return options
    }
}
export const langsdata = [
    { id: 1, name: "Arabic", icon: "AR", childs: [{ id: 11, name: "Syria", icon: "SY", childs: [{ id: 111, name: "Damascus", icon: "DA", childs: [] }, { id: 112, name: "Aleppo", icon: "AL", childs: [] }, { id: 113, name: "Latakia", icon: "LA", childs: [] }] }, { id: 12, name: "Iraq", icon: "IQ", childs: [] }, { id: 12, name: "Lebanon", icon: "LB", childs: [] }] },
    { id: 2, name: "Turkish", icon: "TR", childs: [] },
    { id: 3, name: "English", icon: "EN", childs: [] }
]
export const GetTransValidate = (arr) => {
    let bool = true
    arr.map((name) => {
        if (name.name.length === 0)
            bool = false
    })
    return bool
}